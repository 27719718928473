<template>
  <div class="flex flex-col gap-y-[8px]">
    <HomeSectionTitle
      :title="title"
      :loading="isLoading"
      :tag-line="tagLine"
      :link="seeMoreLink"
      @on-link-clicked="onSeeMoreClicked"
    />
    <RestaurantCardSlider
      :restaurants="showedRestaurants"
      :slide-per-view="slidePerView"
      :is-loading="isLoading"
      :side-image="sideImage"
      :simple="simple"
      @on-card-clicked="onCardClicked"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import type {
  RestaurantCardProps,
  RestaurantCardSliderProps,
} from "~/types/Restaurant";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";
import RestaurantCardSlider from "~/section/restaurant_card_slider/RestaurantCardSlider.vue";

interface Props {
  isLoading: boolean;
  slidePerView: string | number;
  sideImage: boolean;
  seeMoreLink: string;
  showSeeMore: boolean;
  title: string;
  tagLine?: string;
  simple?: boolean;
  restaurants: RestaurantCardSliderProps["restaurants"];
}

const props = defineProps<Props>();
const emits = defineEmits<{
  (
    e: "on-card-clicked",
    data: { card: RestaurantCardProps; position: number | string }
  ): void;
  (e: "on-see-more-clicked", data: string): void;
}>();

const restaurantsDummy = ref<RestaurantCardSliderProps["restaurants"]>([]);
const showedRestaurants = computed(() => {
  return props.isLoading ? restaurantsDummy.value : props.restaurants;
});

function onCardClicked({
  card,
  position,
}: {
  card: RestaurantCardProps;
  position: number | string;
}) {
  emits("on-card-clicked", { card, position });
}

function onSeeMoreClicked(link: string) {
  emits("on-see-more-clicked", link);
}
// await initDummy();
</script>
<script lang="ts">
export default {
  name: "HomeSliderSection",
};
</script>

<style>
.restaurant-card-slide:first-child {
  @apply ml-3;
}
</style>
